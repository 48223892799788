body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.table-borderless td,
.table-borderless th {
    border: 0;
}
.container-fluid {
    height: 100vh;
}
